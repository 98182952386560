<template>
  <div>
    <div class="banner" id="aboutus_banner1" :style="{backgroundImage:('url('+banner+')')}">
      <!-- <img
              :src="banner"
      /> -->
      <span
      >{{ $t("indexnav.Awards & Recognitions")
        }}<i
                :style="{
            display: this.$store.state.nowlanguage === 'en' ? 'none' : '',
          }"
        >Awards & Recognitions</i
        ></span
      >
    </div>

    <section class="ab-item1">
      <!-- <a href="/">{{ $t("indexnav.Home") }}</a> >
      <a href="/aboutus/synopsis">{{ $t("indexnav.About jinxin") }}</a> >
      <a>{{ $t("indexnav.Awards & Recognitions") }}</a> -->
      <bread-crumb />
    </section>
    <article>
      <div>
      <div class="loading_box" v-if="showLoading"></div>
        <figure>
          <!-- ==== -->
          <dl v-for="item in HonorList" :key="item.id">
            <!-- <p>{{item.name.title}}</p> -->
            <dd>
              <a>
                <a v-viewer="{ movable: false }" transition movable>
                  <!-- <HonorImg imglists=item.imgUrl></HonorImg> -->
                  <img :src="item.imgUrl" alt="" />
                </a>
              </a>
            </dd>
            <dd>
              <!-- 项目名称 -->
              <strong>{{ $t("Honor.title") }}</strong>
              <span>{{ filterLang(item, "ProjectName") }}</span>
              <!-- <span v-if="$store.state.nowlanguage==='zh'">{{item.sampleProjectName}}</span>
              <span v-if="$store.state.nowlanguage==='en'">{{item.enProjectName}}</span>
              <span v-if="$store.state.nowlanguage==='tr'">{{item.tranditionalProjectName}}</span> -->
            </dd>
            <dd>
              <!-- 认证 -->
              <strong>{{ $t("Honor.certification") }}</strong>
              <span v-if="$store.state.nowlanguage === 'zh'">{{
                item.sampleRenzheng
              }}</span>
              <span v-if="$store.state.nowlanguage === 'en'">{{
                item.enRenzheng
              }}</span>
              <span v-if="$store.state.nowlanguage === 'tr'">{{
                item.tranditionalRenzheng
              }}</span>
            </dd>
            <dd>
              <!-- 颁发时间 -->
              <strong>{{ $t("Honor.time") }}</strong> {{ item.issuedTime }}
            </dd>
            <dd>
              <!-- 颁发机构 institutions-->
              <strong>{{ $t("Honor.institutions") }}</strong
              >
              <span v-if="$store.state.nowlanguage === 'zh'">{{
                item.sampleIssuedOrg
              }}</span>
              <span v-if="$store.state.nowlanguage === 'en'">{{
                item.enRenzheng
              }}</span>
              <span v-if="$store.state.nowlanguage === 'tr'">{{
                item.tranditionalRenzheng
              }}</span>
            </dd>
          </dl>
        </figure>
      </div>
    </article>
  </div>
</template>

<script>
import { Honordata } from "@/api/aboutus";
import { filterLang } from "@/utils/LangChange";
// import HonorImg from "@/components/Honorimg.vue";
import { PageType } from '@/utils/constant';
import {getAction} from '@/api/manage'

export default {
  components: {
    // HonorImg,
  },
  data() {
    return {
      DATA: "传入的参数",
      HonorList: [],
      nowLanguage: "",
      imglist: [],
      showLoading:true,
      banner: ''
    };
  },
  created() {
    // 初始化banner
    let that = this;
    getAction("/jeecg-system/common/frontend/advertise/active_list", {typeCode: PageType.QIYERONGYU}).then(res => {
      if (res.result.records instanceof Array && res.result.records.length > 0) {
        if (res.result.records[0].isMultipatLanguge!=='0') {
          if (this.$store.state.nowlanguage == "zh")
            that.banner = res.result.records[0].sampleImg;
          if (this.$store.state.nowlanguage == "tr")
            that.banner = res.result.records[0].tranditionalImg;
          if (this.$store.state.nowlanguage == "en")
            that.banner = res.result.records[0].enImg
        }else{
        that.banner = res.result.records[0].sampleImg
        }
      }
    });

    this.nowLanguage = this.$store.state.nowlanguage;
    let arr = [];
    Honordata().then((res) => {
      this.showLoading=false
      this.imglist = res.result.filter((res) => {
        if (res.imgUrl) {
          arr.push(res.imgUrl);
        }
      });
      this.imglist = arr;
      this.HonorList = res.result;
      // filterLang(res.result, "sample");
    });
  },
  methods: {
    filterLang,
  },
  watch: {
    "this.$store.state.nowlanguage"(newval, oldval) {
      this.nowLanguage = newval;
    },
  },
};
</script>

<style scoped>
/* @import url(base.min.css); */

header + a img {
  max-width: 100%;
  width: 100%;
}

.banner img {
  width: 100%;
  max-width: 100vw;
  display: block;
}

section {
  margin: 1.5em auto 0 auto;
  width: 100%;
  max-width: 1200px;
}

.ab-item1 {
  padding: 0 0 1.5em 0;
  border-bottom: 1px solid #d2d2d2;
  font-size: 1.5rem;
  color: #595757;
}

article {
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  overflow: hidden;
}

article div img {
  max-width: 800px;
  height: auto;
}

article div > * {
  margin-top: 1em;
}

article div figure {
  display: flex;
  flex-wrap: wrap;
}

article div figure dl {
  margin-top: 2em;
  width: 30%;
  margin-right: 5%;
}

article div figure dl:nth-child(3),
article div figure dl:nth-child(6),
article div figure dl:nth-child(9),
article div figure dl:nth-child(12),
article div figure dl:nth-child(15),
article div figure dl:nth-child(18),
article div figure dl:nth-child(21),
article div figure dl:nth-child(24) {
  margin-right: 0;
}

article div figure dl dd strong {
  color: #165da3;
}

article div figure dd:first-child {
  padding: 0.5em;
  background-color: #eee;
  overflow: hidden;
}

article div figure dd {
  color: #221815;
  line-height: 2em;
}

article div figure dd:first-child + dd {
  margin-top: 1em;
}

article div figure img {
  margin: 0 auto;
  max-width: 330px;
  max-height: 250px;
  display: block;
}

article div figure dl dd:first-child {
  border: 1px solid #eee;
}

article div figure dl dd:first-child:hover {
  border: 1px solid #c81a41;
}

@media screen and (max-width: 1200px) {
  .ab-item1 {
    font-size: 1.2em;
    padding: 0 1.5em 1.5em 1.5em;
  }

  article div {
    font-size: 1.2em;
    padding: 0 1.5em 1.5em 1.5em;
  }

  article div figure dl {
    width: 45%;
    margin-right: 10%;
  }

  article div figure dl:nth-child(3),
  article div figure dl:nth-child(9),
  article div figure dl:nth-child(15),
  article div figure dl:nth-child(21) {
    margin-right: 10%;
  }

  article div figure dl:nth-child(2),
  article div figure dl:nth-child(4),
  article div figure dl:nth-child(6),
  article div figure dl:nth-child(8),
  article div figure dl:nth-child(10),
  article div figure dl:nth-child(12),
  article div figure dl:nth-child(14),
  article div figure dl:nth-child(16),
  article div figure dl:nth-child(18),
  article div figure dl:nth-child(20),
  article div figure dl:nth-child(22) {
    margin-right: 0;
  }
}

@media screen and (max-width: 768px) {
  article div img {
    max-width: 100%;
  }

  article div figure dl {
    width: 100%;
    margin-right: 0;
  }
}

@media screen and (max-width: 579px) {
  article div figure img {
    max-width: 95%;
  }
}
</style>
